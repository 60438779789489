:root {
  font-family: Poppins, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  color-scheme: light dark;
  background: rgba(245, 245, 245, 1);
  margin: 0px 42px;
}

.html {
  background-color: rgba(229, 229, 229, 1);
}

.MuiFormControlLabel-label {
  color: #000;
}
